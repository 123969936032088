import React from 'react';
import Hero from '@components/Hero';
import Streamfields from '@streamfields/Streamfields';
import type { Streamfield, WagtailImageRendition } from '@/types';
import BannerImage from '@components/BannerImage';
import FakeSearch, { FakeSearchProps } from '@components/Fakesearch';
import clsx from 'clsx';
import { basePageWrap, BasePageProps } from '../BasePage';

type Props = {
  title: string;
  subheader: string;
  subtitle: string;
  content: Streamfield[];
  banner_image: WagtailImageRendition | null;
  mobile_banner_image: WagtailImageRendition | null;
  fakesearch_data: FakeSearchProps;
} & BasePageProps;

function HomePage(props: Props) {
  const {
    title,
    subheader,
    subtitle,
    content,
    banner_image,
    mobile_banner_image,
    blocksLoaderData,
  } = props;

  return (
    <div className='home-page'>
      <div
        className={clsx({
          'hero-content': banner_image,
        })}
      >
        {banner_image ? (
          <BannerImage image={banner_image} mobileImage={mobile_banner_image} />
        ) : null}

        <Hero title={title} subtitle={subheader} text={subtitle} />
      </div>
      {props.fakesearch_data ? <FakeSearch {...props.fakesearch_data} /> : null}
      {content ? <Streamfields fields={content} blocksLoaderData={blocksLoaderData} /> : null}
    </div>
  );
}

export default basePageWrap<Props>(HomePage);
